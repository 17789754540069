.card-container {
	width: 300px;
	overflow: hidden;
	margin: 4rem;
	border-radius: 2px;
	border: solid 1px #ffd70096;
	min-width: 300px;

	.top-half {
		display: flex;
		justify-content: center;
		align-items: center;
		min-height: 100px;
		background: linear-gradient(161deg, #254780, #23305f);
		border-bottom: solid 1px #ffd700d1;
		.title {
			color: white;
			text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
		}
	}

	.bottom-half {
		padding: 20px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		background: linear-gradient(159deg, color(srgb 0.18 0.26 0.44), #23305f);
		color: white;
		height: 300px;

		h3 {
			text-align: center;
		}

		p {
			margin-bottom: 20px;
		}

		a {
			width: 100%;
		}

		button {
			padding: 10px 20px;
			border: solid 1px;
			border-color: white;
			border-radius: 4px;
			background-color: #daa52000;
			color: white;
			cursor: pointer;
			transition: all 0.1s;
			font-weight: bold;
			margin-bottom: 10px;
			width: 100%;

			&:hover {
				background-color: #ae8319;
				border-color: #ae8319;
			}
		}
	}
}
